.modal-dialog {
  max-width: fit-content !important;
  .modal-content {
    border: none !important;
    border-radius: 0% !important;
    box-shadow: 0px 4px 8px 0px #00000040;
    .modal-header {
      border: none !important;
      .modal-close-icon {
        font-size: 32px;
        margin-top: -50px;
        cursor: pointer;
      }
    }
    .modal-footer {
      border: none !important;
      border-radius: 0% !important;
      button.cancel {
        width: 100px;
      }
    }
  }
}
