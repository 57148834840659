/* You can add global styles to this file, and also import other style files */
@import './_variables';
@import './modal';
@import './toast.scss';

html {
  font-size: 16px; // 1 rem. Used by bootstrap spacing e.g. mt-1 = margin-top: 4px (or 1/4 of a rem)
}

body {
  height: 100vh;
  overflow-x: none;
  overflow-y: auto;
}

h1,
label,
p,
input,
select,
button,
div {
  font-family: Open Sans;
  font-size: 0.8125rem;
  color: $color-clickDimensions-text;
  .text-small {
    font-size: 0.625rem;
  }
}

input[type='text'],
select {
  border-color: $color-input-border;
  border-width: 1px;
  border-style: solid;
  padding: 5px;
}

h1 {
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
}

button {
  font-style: normal;
  line-height: 24px;
  text-align: center;
  border: 1px solid;
  cursor: pointer;
  height: 30px;
  padding: auto;
  user-select: none;
  background-color: white;
  color: $color-secondary;
  border-color: $color-secondary;
  &.primary {
    background-color: $color-clickDimensions-text;
    color: white;
    border: none;
    &:hover {
      color: $color-clickDimensions-text;
      background-color: #efefef;
      border: 1px solid $color-clickDimensions-text;
    }
  }
  &:disabled {
    color: grey;
    border-color: grey;
    cursor: default;
  }
  &:hover {
    background-color: #dbeefb;
  }
}

input,
select {
  &:disabled {
    background-color: $color-disabled;
  }
}

.menu-heading {
  font-size: 1rem;
  &.active {
    font-weight: 700;
  }
  &.inactive {
    font-weight: 400;
  }
}

.cd-spinner {
  color: $color-neutral;
}

.info-icon {
  user-select: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: lightgrey;
}

::-webkit-scrollbar-thumb:hover {
  background: #666;
}
