// regular style toast
@import '~ngx-toastr/toastr';

#toast-container {
  .ngx-toastr {
    margin-bottom: 60px;
  }
  .toast-error > div {
    color: white;
  }
  .toast-success > div {
    color: white;
  }
  .toast-info > div {
    color: white;
    opacity: 0.85;
    pointer-events: none;
  }
}
